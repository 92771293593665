import "../assets/css/Footer.css"

function Footer() {
    return (
        <footer className="footer">
            <svg
                className="footer__logo"
                width="244"
                height="111"
                viewBox="0 0 244 111"
                fill="black"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M25.3207 110.319C13.8904 106.636 8.04281 98.1993 7.77787 85.0083V72.8321C7.77787 64.9776 5.18525 61.0504 0 61.0504V49.3251C5.18525 49.3251 7.77787 45.3415 7.77787 37.3743V25.0853C8.08066 12.0447 13.9283 3.68294 25.3207 0L28.3864 9.13219C24.6015 10.9361 22.5388 15.6149 22.1982 23.1687L22.1414 37.2616C22.1414 45.7173 19.1324 51.6739 13.1145 55.1314C19.1324 58.6264 22.1414 64.6582 22.1414 73.2267C22.1414 81.7951 22.1792 86.6995 22.2549 87.9396C22.6713 95.0049 24.6962 99.4019 28.3296 101.131L25.3207 110.319Z" />
                <path d="M82.5382 21.6279H96.4475V68.98C96.4475 75.6193 94.5866 80.9433 90.8649 84.9519C87.1431 88.9606 82.3489 90.9649 76.4824 90.9649C70.1743 90.9649 65.2698 89.1015 61.7688 85.3747C58.2678 81.6479 56.5173 76.4649 56.5173 69.8256H70.474C70.474 76.277 72.4768 79.5027 76.4824 79.5027C80.5196 79.5027 82.5382 75.7759 82.5382 68.3223V21.6279Z" />
                <path d="M131.032 90.0254C130.653 89.2424 130.275 87.9271 129.896 86.0794C127.499 89.3364 124.25 90.9649 120.15 90.9649C115.861 90.9649 112.297 89.5556 109.458 86.737C106.651 83.9185 105.247 80.27 105.247 75.7916C105.247 70.4676 106.95 66.3493 110.357 63.4368C113.795 60.5243 118.731 59.0367 125.165 58.974H129.234V54.8871C129.234 52.6009 128.84 50.9881 128.051 50.0486C127.263 49.109 126.111 48.6393 124.597 48.6393C121.254 48.6393 119.582 50.5809 119.582 54.4643H106.194C106.194 49.7667 107.96 45.899 111.492 42.8612C115.056 39.7921 119.551 38.2575 124.976 38.2575C130.59 38.2575 134.927 39.7138 137.986 42.6263C141.077 45.5075 142.623 49.6414 142.623 55.028V78.939C142.686 83.3234 143.317 86.7527 144.515 89.2268V90.0254H131.032ZM123.131 80.5362C124.613 80.5362 125.875 80.2387 126.916 79.6436C127.956 79.0486 128.729 78.3439 129.234 77.5297V66.96H126.017C123.746 66.96 121.948 67.6803 120.623 69.1209C119.33 70.5615 118.684 72.4876 118.684 74.899C118.684 78.6571 120.166 80.5362 123.131 80.5362Z" />
                <path d="M168.36 71.2349L174.841 39.197H188.798L174.747 90.0254H161.973L147.874 39.197H161.878L168.36 71.2349Z" />
                <path d="M215.614 101.131C217.733 100.153 219.304 98.3308 220.326 95.6625C221.348 93.0319 221.859 89.1798 221.859 84.1064V73.0575C221.859 64.4515 224.943 58.4949 231.113 55.1878C224.943 51.843 221.859 45.8677 221.859 37.2616C221.859 28.6555 221.84 23.9579 221.802 23.1687C221.461 15.5773 219.38 10.8985 215.557 9.13219L218.623 0C224.451 1.87905 228.804 4.92312 231.68 9.13219C234.595 13.3413 236.09 18.8657 236.165 25.7054V37.4307C236.165 45.3603 238.777 49.3251 244 49.3251V61.0504C238.777 61.0504 236.165 65.0152 236.165 72.9448V85.1774C235.863 98.2556 230.015 106.636 218.623 110.319L215.614 101.131Z" />
            </svg>

            <div className="footer__social-media">
                <a
                    href="https://twitter.com/javcho23"
                    target="_blank"
                    rel="noreferrer"
                >
                    <svg
                        className="footer__social-media-item"
                        width="60"
                        height="49"
                        viewBox="0 0 60 49"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M18.8688 48.7531C41.5104 48.7531 53.8944 29.9947 53.8944 13.7275C53.8944 13.1947 53.8944 12.6643 53.8584 12.1363C56.2676 10.3937 58.3473 8.23603 60 5.76432C57.7533 6.75985 55.37 7.41271 52.9296 7.70112C55.4994 6.16268 57.4227 3.74297 58.3416 0.892321C55.9252 2.32621 53.2815 3.33675 50.5248 3.88032C48.6688 1.90676 46.2141 0.599924 43.5405 0.162032C40.8669 -0.27586 38.1236 0.179607 35.7349 1.45795C33.3463 2.7363 31.4456 4.76625 30.3269 7.23369C29.2082 9.70113 28.9339 12.4685 29.5464 15.1075C24.6522 14.8622 19.8643 13.5903 15.4935 11.3745C11.1228 9.15876 7.26681 6.04858 4.176 2.24592C2.60182 4.95591 2.11968 8.16399 2.82775 11.217C3.53582 14.27 5.38085 16.9383 7.9872 18.6787C6.02818 18.6207 4.11187 18.0922 2.4 17.1379C2.4 17.1883 2.4 17.2411 2.4 17.2939C2.40078 20.136 3.38463 22.8904 5.18468 25.0898C6.98473 27.2893 9.49015 28.7983 12.276 29.3611C10.4637 29.8554 8.56219 29.9276 6.7176 29.5723C7.50422 32.0183 9.03568 34.1573 11.0978 35.6901C13.1599 37.2229 15.6495 38.0729 18.2184 38.1211C13.8594 41.547 8.47454 43.4067 2.9304 43.4011C1.95097 43.3992 0.97249 43.3399 0 43.2235C5.62953 46.8362 12.1798 48.7524 18.8688 48.7435" />
                    </svg>
                </a>
                <a
                    href="https://linkedin.com/in/javcho23"
                    target="_blank"
                    rel="noreferrer"
                >
                    <svg
                        className="footer__social-media-item"
                        width="60"
                        height="58"
                        viewBox="0 0 60 58"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0)">
                            <path d="M0 6.66023C0 4.72969 0.675695 3.13703 2.02703 1.88224C3.37836 0.627394 5.13515 0 7.2973 0C9.42087 0 11.139 0.617722 12.4517 1.85328C13.8031 3.12741 14.4788 4.78761 14.4788 6.83398C14.4788 8.68726 13.8224 10.2316 12.5097 11.4672C11.1583 12.7413 9.38224 13.3784 7.18147 13.3784H7.12355C4.99998 13.3784 3.28187 12.7413 1.96911 11.4672C0.656351 10.1931 0 8.59071 0 6.66023ZM0.752896 57.3359V18.6486H13.61V57.3359H0.752896ZM20.7336 57.3359H33.5907V35.7336C33.5907 34.3822 33.7452 33.3397 34.0541 32.6062C34.5946 31.2934 35.4151 30.1834 36.5154 29.2761C37.6158 28.3687 38.9961 27.9151 40.6564 27.9151C44.9807 27.9151 47.1429 30.8301 47.1429 36.6602V57.3359H60V35.1544C60 29.4401 58.6487 25.1062 55.9459 22.1525C53.2432 19.1988 49.6718 17.722 45.2317 17.722C40.251 17.722 36.3707 19.8649 33.5907 24.1506V24.2664H33.5328L33.5907 24.1506V18.6486H20.7336C20.8108 19.8842 20.8494 23.7258 20.8494 30.1737C20.8494 36.6216 20.8108 45.6756 20.7336 57.3359Z" />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="60" height="57.336" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
                <a
                    href="https://github.com/javcho23"
                    target="_blank"
                    rel="noreferrer"
                >
                    <svg
                        className="footer__social-media-item"
                        width="60"
                        height="59"
                        viewBox="0 0 60 59"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0)">
                            <path d="M29.884 0C13.3816 0 0 13.3793 0 29.884C0 43.0877 8.56266 54.2895 20.4366 58.241C21.93 58.5176 22.4784 57.5927 22.4784 56.8034C22.4784 56.0909 22.4505 53.7366 22.4379 51.2395C14.1239 53.0473 12.3696 47.7136 12.3696 47.7136C11.0102 44.2594 9.05156 43.3411 9.05156 43.3411C6.34031 41.4863 9.25594 41.5242 9.25594 41.5242C12.2569 41.7352 13.837 44.6039 13.837 44.6039C16.5023 49.1726 20.828 47.8516 22.5333 47.0883C22.8014 45.1566 23.576 43.8384 24.4305 43.0922C17.793 42.3363 10.8152 39.7739 10.8152 28.3233C10.8152 25.0608 11.9827 22.3948 13.8945 20.302C13.5841 19.5492 12.5613 16.5098 14.1839 12.3935C14.1839 12.3935 16.6934 11.5903 22.4041 15.4568C24.7877 14.7945 27.3443 14.4626 29.884 14.4513C32.4237 14.4626 34.9821 14.7945 37.3704 15.4568C43.0744 11.5903 45.5803 12.3935 45.5803 12.3935C47.2069 16.5098 46.1836 19.5492 45.8733 20.302C47.7893 22.3948 48.9487 25.0605 48.9487 28.3233C48.9487 39.8011 41.9578 42.3286 35.3034 43.0683C36.3752 43.9957 37.3303 45.8145 37.3303 48.6023C37.3303 52.6008 37.2956 55.819 37.2956 56.8034C37.2956 57.5986 37.8335 58.5305 39.3485 58.237C51.2159 54.281 59.7677 43.0833 59.7677 29.884C59.7677 13.3793 46.388 0 29.884 0Z" />
                            <path d="M11.1926 42.5707C11.127 42.7191 10.8931 42.7636 10.6805 42.6619C10.4637 42.5644 10.3418 42.3619 10.4121 42.2128C10.4766 42.06 10.7105 42.0174 10.9268 42.12C11.1441 42.2173 11.2679 42.4217 11.1926 42.5707ZM12.6626 43.8823C12.5201 44.0145 12.2414 43.9531 12.0523 43.7442C11.8568 43.5359 11.8203 43.2574 11.9649 43.1231C12.1118 42.9912 12.3821 43.0528 12.578 43.2614C12.7735 43.4721 12.8115 43.7489 12.6624 43.8825L12.6626 43.8823ZM13.6711 45.5604C13.4879 45.6877 13.1883 45.5684 13.0034 45.3026C12.8204 45.037 12.8204 44.7183 13.0074 44.5906C13.193 44.4628 13.4879 44.5777 13.6754 44.8413C13.8582 45.1116 13.8582 45.4303 13.6709 45.5606L13.6711 45.5604ZM15.3765 47.5041C15.2126 47.6845 14.8639 47.6363 14.6084 47.3897C14.3473 47.1488 14.2744 46.8068 14.4387 46.6261C14.6044 46.4452 14.9553 46.496 15.2126 46.7405C15.4721 46.9809 15.5513 47.3255 15.3767 47.5041H15.3765ZM17.5805 48.1603C17.5086 48.3942 17.1725 48.5006 16.834 48.4013C16.4961 48.2988 16.2748 48.0246 16.343 47.7881C16.4133 47.5526 16.7508 47.4417 17.0919 47.5481C17.4294 47.6501 17.6511 47.9222 17.5808 48.1603H17.5805ZM20.0893 48.4385C20.0977 48.6851 19.8106 48.8895 19.4553 48.8939C19.0979 48.9017 18.8089 48.7022 18.8051 48.4599C18.8051 48.2109 19.0857 48.0084 19.4429 48.0026C19.7982 47.9956 20.0893 48.1936 20.0893 48.4385ZM22.5535 48.3441C22.5962 48.5845 22.3491 48.8316 21.9964 48.8972C21.6495 48.9605 21.3284 48.8121 21.2841 48.5738C21.241 48.3272 21.4927 48.0804 21.8389 48.0164C22.1923 47.955 22.5085 48.0996 22.5535 48.3441Z" />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="60" height="58.368" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
            </div>
            <p className="footer__slogan">
                Lo contrario de la guerra no es la paz, es la creación
            </p>
            <p className="footer__rights">Todos los derechos reservados</p>
        </footer>
    )
}

export default Footer
